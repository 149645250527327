import React from "react"

const ActionButton = ({type, address, text}) => {
  return (
    <a href={address} target="_blank" rel="noopener noreferrer">
      <li className="formular-download-link" style={{display: 'flex', alignItems: 'center'}}>
        <span className={type} />{text}
      </li>
    </a>
  )
}

export default ActionButton
