import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const LeitbildOrganigramm = ({data}) => {
  return (
    <Layout title="Leitbild & Organigramm" headerImage={data.image1.childImageSharp.fluid}>
      <div>
        <h3>Leitbild</h3>
        <h4>Im Mittelpunkt unsere Tätigkeit steht die Lebensqualität unserer Bewohnenden</h4>
        <p>
        Unseren Bewohnenden bringen wir Achtung und Wertschätzung entgegen.
<br/>Sie sollen sich in einer Atmosphäre der Sicherheit und Geborgenheit wohlfühlen. Zufriedenheit, größtmögliche Selbstbestimmung und Eigenverantwortung unserer Bewohnenden sind uns wichtig.

<br/><br/>Wir begegnen den Bewohnenden mit Verständnis und Einfühlungsvermögen und berücksichtigen ihre Gewohnheiten und Vorlieben auf dem Hintergrund ihrer Biografie.

<br/><br/>Um Gesundheit und Selbstständigkeit zu bewahren und zu fördern unterstützen wir unsere Bewohnenden mit fachlich kompetenter Pflege und Betreuung soweit nötig. Die Hilfe zur Selbsthilfe steht dabei im Vordergrund.

<br/><br/>Wir motivieren unsere Bewohnenden aktiv am Leben im Heim teilzunehmen und die Beziehungen zu Verwandten, Bekannten und dem Dorfleben so weit als möglich zu pflegen.

<br/><br/>Wir fördern ein Klima des gegenseitigen Respektes und setzen uns dafür ein, dass es keine Diskriminierungen gibt.

<br/><br/>Wir informieren unsere Bewohnenden stets rechtzeitig und verständlich über alles was sie betrifft. Für Anliegen und Anregungen von Angehörigen haben wir immer ein offenes Ohr.

<br/><br/>Die Mitarbeitenden setzen sich mit hoher Selbst-, Sozial-, und Fachkompetenz für das Wohlbefinden der Bewohnenden ein. Wir informieren uns aktiv und umfassend über die aktuellen Erkenntnisse aus der Forschung zum Wohle der Bewohnenden. Wir sind offen und zeigen Mut zur Veränderung.

<br/><br/>Unser Ziel ist es, dass Bewohnende in Würde und möglichst nach ihren Wünschen bei uns alt werden dürfen. Wir legen einen grossen Wert darauf, dass unsere Bewohnenden die Möglichkeit haben, ihren Lebensabend in Würde zu verbringen und ihre individuellen Wünsche entsprechen berücksichtigt werden.

<br/><br/>Dennoch wird entschieden, im Altersheim Bannau der evangelischen Kirchgemeinde haben Sterbeorganisationen keinen Zutritt. Allerdings heissen wir Mitglieder einer Sterbeorganisation herzlich als Bewohnende willkommen. Sollte der Wunsch nach einer passiven Sterbehilfe aufkommen, werden wir sie bei der Suche nach einer angemessenen Lösung unterstützen.

        </p>
        <ActionButton type="icon-pdf" address="/download/Organigramm-Bannau.pdf" text="Organigramm" />
      </div>
    </Layout>
  ) 
}

export default LeitbildOrganigramm

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`